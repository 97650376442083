@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #fafafa; */
}
html {
  scroll-behavior: smooth;
}
p {
  margin: 0;
}
h1,
h2 {
  margin: 0;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
}
.nav_main {
  background: black;
  padding-top: 24px;
  padding-bottom: 24px;
}
.nav_1 {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  gap: 20px;
}
.nav_2 {
  display: flex;
  align-items: center;
  gap: 50px;
  width: 100%;
}
.logo {
  width: 153px;
}
.nav_3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 607px;
  width: 100%;
  /* margin: auto; */
}
.mobilenav {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #000;
}
.xmark {
  display: none;
}
@media (max-width: 1024px) {
  .xmark {
    display: block;
    position: absolute;
    right: 20px;
    top: 10px;
  }
  .mobilenav {
    display: flex;
  }
  .nav_main {
    position: fixed;
    transform: translateX(-2000px);
  }
  .nav_1 {
    flex-direction: column;
  }
  .nav_2 {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .nav_3 {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
  }
}
.translateFull {
  transform: translateX(0px) !important;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  background-color: black;
  position: fixed;
  width: 100%;
  z-index: 100000;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  gap: 20px;
  height: 100vh;
  padding-bottom: 50px;
  /* top: 60px; */
  overflow-y: scroll;
}
.active {
  color: #ee1b50 !important;
  border-bottom: 1px solid #ee1b50 !important;
  padding-bottom: 6px;
}

.nav_list {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  padding-bottom: 6px;
}
.nav_list1 {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  padding-bottom: 6px;
}
.nav_list2 {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  padding-bottom: 6px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.btn1 {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 375% */
  border-radius: 4px;
  background: #ed1b50;
  width: 185.61px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ed1b50;
  outline: none;
  cursor: pointer;
}
.btn1:hover {
  background-color: transparent;
  color: #ed1b50;
}
/* hero */
.hero_main {
  position: relative;
  width: 100%;
}
.herobg {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -10;
  object-fit: cover;
  height: 460px;
}
@media (max-width: 1030px) {
  .herobg {
    height: 100%;
  }
}
.hero_main1 {
  padding-top: 60px;
  padding-bottom: 130px;
  max-width: 1370px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
}
.hero_1 {
  color: #fff;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 110.25%; /* 70.56px */
}
.hero_2 {
  color: #fff;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 110.25%; /* 70.56px */
}
.hero_3 {
  color: #ee1b50;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 110.25%; /* 70.56px */
}
.hero_4 {
  margin-top: 16px;
  margin-bottom: 32px;
  color: #c4c4c4;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.btn2 {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 375% */
  border-radius: 10px;
  background: #ed1b50;
  width: 200px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ed1b50;
  outline: none;
  cursor: pointer;
}
.btn2:hover {
  background-color: transparent;
  color: #ed1b50;
}
/* world wide */
.world_main {
  padding-top: 60px;
  padding-bottom: 100px;
  max-width: 1370px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
}
.world_1 {
  color: var(--Secondary, #000);
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  text-transform: uppercase;
  max-width: 920px;
  width: 100%;
  margin: auto;
}
.world_2 {
  color: var(--Secondary, #ee1b50);
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  text-transform: uppercase;
}
.ch {
  font-size: 35px;
}
.world_p {
  margin-top: 12px;
  margin-bottom: 80px;
  color: #72839a;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.world_3 {
  display: flex;
  justify-content: center;
  gap: 20px;
}
@media (max-width: 1024px) {
  .world_3 {
    flex-wrap: wrap;
  }
  .world_5 {
    width: 100%;
  }
  .world_5 img {
    width: 100%;
  }
}
.world_4 {
  max-width: 305px;
  width: 100%;
}
.world_4_1 {
  color: #222;
  /* text-align: center; */
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 38px; /* 126.667% */
}
.world_4_2 {
  color: #72839a;
  /* text-align: center; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 162.5% */
  margin-top: 20px;
}
.world_6 {
  max-width: 350px;
  width: 100%;
}
.world_6_1 {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #222;
  /* text-align: center;
  font-family: Montserrat; */
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 38px; /* 126.667% */
  margin-left: -50px;
}
@media (max-width: 768px) {
  .world_6_1 {
    margin-left: 0px;
  }
}
.world_6_2 {
  color: #ee1b50;
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: 38px;
}
.world_6_3 {
  margin-bottom: 38px;
  color: #72839a;
  /* text-align: center; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 162.5% */
}
.btn4 {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 375% */
  border-radius: 4px;
  background: #ed1b50;
  width: 184px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: 1px solid #ed1b50;
  outline: none;
  cursor: pointer;
}
.btn4:hover {
  background-color: transparent;
  color: #ed1b50;
}
/* Choose Us */

.choose_main {
  position: relative;
}
.choosebg {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -10;
  height: 600px;
}
@media (max-width: 1024px) {
  .choosebg {
    height: 100%;
  }
}
.choose_1 {
  padding-top: 60px;
  color: var(--Secondary, #fff);
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 166.667% */
  text-transform: uppercase;
}
.choose_2 {
  color: var(--Secondary, #ee1b50);
}
.choose_3 {
  margin-top: 10px;
  margin-bottom: 50px;
  color: #e5e5e5;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px; /* 159.091% */
}
.choose_4 {
  color: #d4d4d4;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
  text-align: center;
}
.choose_5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
  max-width: 1370px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1024px) {
  .choose_5 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.choose_6 {
  max-width: 280px;
  width: 100%;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 33px;
  background-color: rgba(0, 0, 0, 0.51);
  backdrop-filter: blur(11.600000381469727px);
}
.choose_7 {
  color: #fff;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}
.choose_8 {
  color: #d4d4d4;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
/* Service Area */
.exm {
  margin-top: 40px;
}
.service_1 {
  margin-top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.border-left {
  border-left: 4px solid #e9f0fb;
  border-right: 4px solid #e9f0fb;
}
.service_2 {
  background: #fff;
  max-width: 394px;
  width: 100%;
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 30px;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 50px;
  padding-bottom: 50px;
  border-top: 4px solid #e9f0fb;
  border-bottom: 4px solid #e9f0fb;
}
@media (max-width: 1221px) {
  .service_2 {
    border-left: 4px solid #e9f0fb;
    border-right: 4px solid #e9f0fb;
  }
}
.service_3 {
  color: #222;
  font-size: 27px;
  font-style: normal;
  font-weight: 800;
  line-height: 35.1px; /* 130% */
  text-transform: uppercase;
  position: relative;
  z-index: 10;
  text-align: left !important;
  /* margin-top: 20px; */
}
.zeroone {
  position: absolute;
  z-index: -1;
  top: -15px;
  left: -5px;
}
.service_4 {
  color: #72839a;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
}
.service_5 {
  color: var(--Secondary, #ee1b50);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 31.5px; /* 175% */
  display: flex;
  gap: 10px;
  cursor: pointer;
  align-items: center;
}
/* Projects */
.projects_main {
  background-color: #ffffff;
  padding-top: 80px;
  padding-bottom: 80px;
}
.projects_3 h1 {
  color: #000;
}

.projects_1 {
  max-width: 1370px;
  width: 100%;
  /* padding-left: 20px; */
  padding-right: 20px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}
@media (max-width: 1130px) {
  .projects_1 {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    /* padding-left: 20px; */
  }
  .projects_3 {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.projects_2 {
  width: 65%;
  display: flex;
  gap: 32px;
}
@media (max-width: 1120px) {
  .projects_2 {
    width: 100%;
    margin: auto;
  }
  .projects_1 {
    padding-right: 0px;
  }
}
.projects_3 {
  max-width: 580px;
  width: 100%;
}
.projects_4 {
  color: #fff;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  text-align: left !important;
  line-height: 52px;
}
.projects_5 {
  color: var(--Secondary, #ee1b50);
}
.projects_6 {
  color: #000000;
  margin-top: 26px;
  margin-bottom: 34px;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
}
.projects_7 {
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.projects_8 {
  margin-top: 34px;
  display: flex;
  gap: 8px;
}
.projects_8 svg {
  cursor: pointer;
}
.projects_8_mob {
  display: none;
}
@media (max-width: 768px) {
  .projects_8 {
    display: none;
  }
  .projects_8_mob {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    padding-left: 20px;
  }
}
/* Profit */
.profit_main {
  padding-top: 35px;
  padding-bottom: 130px;
  max-width: 1370px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 30px;
}
@media (max-width: 1024px) {
  .profit_main {
    flex-wrap: wrap;
  }
}
.profit_1 {
  max-width: 600px;
  width: 100%;
}
.profit_1 h1 {
  color: var(--Secondary, #222);
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  margin-top: 38px;
}
.profitspan {
  color: var(--Secondary, #ee1b50);
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px; /* 121.053% */
}
.profit_1 p {
  margin-top: 60px;
  color: #72839a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 168.75% */
}
.profit_2 {
  max-width: 560px;
  width: 100%;
}
.profitimg {
  width: 100%;
}
/* Clients */
.clients_main {
  padding-top: 10px;
  padding-bottom: 150px;
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.clients_1 {
  color: #222;
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 133.333% */
  text-transform: uppercase;
  margin-bottom: 120px;
}
.working {
  color: #ee1b50;
}
.clients_2 {
  display: flex;
  justify-content: center;
}
.clients_3 {
  max-width: 445px;
  width: 100%;
}
.clients_4 {
  border-radius: 15px;
  background: #fff;
  box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.34);
  padding-left: 20px;
  padding-bottom: 20px;
  display: flex;
  gap: 26px;
  align-items: center;
  position: relative;
}
.clients_4_right {
  border-radius: 15px;
  background: #fff;
  box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.34);
  padding-right: 20px;
  padding-bottom: 20px;
  display: flex;
  gap: 26px;
  align-items: center;
  position: relative;
  flex-direction: row-reverse;
}
.Arrow-c {
  position: absolute;
  right: -28px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.Arrow-c_right {
  position: absolute;
  left: -28px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  transform: rotate(180deg);
}
@media (max-width: 500px) {
  .Arrow-c {
    right: -18px;
    display: none;
  }
  .Arrow-c_right {
    right: -18px;
    display: none;
  }
}
.clients_5 {
  width: 123px;
  height: 112px;
  border-radius: 0px 0px 10px 10px;
  background: var(--Secondary, #ee1b50);
  color: #fff;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  margin-top: -10px;
}
.step {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.clients_6 {
  color: #404040;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.mt250 {
  margin-top: 275px;
}
.mt279 {
  margin-top: 260px;
  position: relative;
  top: 44px;
}
.mt279_right {
  margin-top: 260px;
  position: relative;
  top: 35px;
}
.-mtc {
  margin-top: -45px;
}
.mt135 {
  margin-top: 157px;
}
@media (min-width: 1024px) {
  .mob2 {
    display: none;
  }
}
@media (max-width: 1024px) {
  .clientline {
    display: none;
  }
  .clients_2 {
    flex-wrap: wrap;
  }
  .mt135 {
    margin-top: 50px;
  }
  .mt250 {
    margin-top: 50px;
  }
  .mt279 {
    margin-top: 50px;
    position: relative;
    top: 10px;
  }
  .mt279_right {
    margin-top: 40px;
    top: 10px;
  }
  .c3mob {
    display: none;
  }
}
/* History */
.history_main {
  background-color: #000;
  padding-top: 48px;
  padding-bottom: 48px;
}
.history_1 {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 55px;
}
.mobile_historyimage {
  display: none;
}
.desktop_historyimage {
  display: block;
}
@media (max-width: 1024px) {
  .history_1 {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .mobile_historyimage {
    display: block;
    margin-bottom: 30px;
  }
  .desktop_historyimage {
    display: none;
  }
}
.history_2 {
  max-width: 562px;
  width: 100%;
}
.historybg {
  width: 100%;
}
.history_3 {
  max-width: 525px;
  width: 100%;
}
.history_4 {
  color: #fff;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  text-transform: uppercase;
}
.historyspan {
  color: #ee1b50;
}
.history_5 {
  margin-top: 60px;
  margin-bottom: 60px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
.btnhistory {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  border-radius: 4px;
  background: #ed1b50;
  min-width: 218px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ed1b50;
  outline: none;
  cursor: pointer;
}
.btnhistory:hover {
  background-color: transparent;
  color: #ed1b50;
}

/* Team */
.team_main {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 56px;
  padding-bottom: 76px;
}
.team_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  margin-top: 50px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
@media (max-width: 1024px) {
  .team_1 {
    justify-content: center;
  }
}
.team_2 {
  width: 300px;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.team_2 h1 {
  margin-top: 20px;
  margin-bottom: 4px;
  color: #333;
  text-align: center;
  font-size: 18.438px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.4px;
}
.team_2 p {
  color: #55676b;
  text-align: center;
  font-size: 19.523px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
}
.btnload {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  border-radius: 4px;
  background: #ed1b50;
  min-width: 185px;
  height: 60px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ed1b50;
  outline: none;
  cursor: pointer;
}
.btnload:hover {
  background-color: transparent;
  color: #ed1b50;
}
/* Testimonials */
.testi_main {
  padding-top: 40px;
}

.testi_1 {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 70px;
  padding-bottom: 150px;
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}
/*  */
.t_cards {
  max-width: 370px;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.15);
  padding-top: 65px;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 45px;
  border-bottom: 8px solid #ed1b50;
  position: relative;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.t_cards:hover {
  box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  background-color: rgba(237, 27, 80, 0.1);
}
.t_cards_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tabler-quote {
  width: 54px;
  position: absolute;
  left: 10px;
  top: -20px;
  transform: rotate(180deg);
}
.t-cards_img {
  margin-bottom: 30px;
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 100%;
  margin-left: auto;
  margin-right: auto;
}
/*  */
.testi_2_black {
  max-width: 657px;
  width: 100%;
  background-color: #000;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  gap: 27px;
  align-items: center;
  border-radius: 16px;
}
.testi_2 {
  max-width: 657px;
  width: 100%;
  background-color: transparent;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  gap: 20px;
  /* align-items: center; */
  border-radius: 16px;
}
/* .testi_2 .userimg{
  height: 253px;
} */
@media (max-width: 550px) {
  .testi_2 {
    flex-wrap: wrap;
  }
  .testi_2 .userimg {
    width: 100%;
  }
}
.testi_3 {
  /* color: #000; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  text-align: center;
}
.testi_3_black {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  text-align: justify;
}
.testi_4_black {
  margin-top: 30px;
  color: #fff;
  font-size: 13.443px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.testi_4 {
  margin-top: 20px;
  /* color: #000; */
  font-size: 13.443px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.testi_5_black {
  color: #fff;
  font-size: 13.443px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.testi_5 {
  margin-top: 20px;
  /* color: #000; */
  font-size: 13.443px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.star_card {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
/* Total */
.total_main {
  background-color: #000;
  padding-top: 70px;
  padding-bottom: 55px;
}
.total_1 {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
}
@media (max-width: 1024px) {
  .total_1 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.total_2 {
  width: 235px;
}
.total_2 h1 {
  color: #fff;
  text-align: center;
  font-size: 55px;
  font-style: normal;
  font-weight: 800;
  line-height: 55px;
}
.plus {
  color: #ed1b50;
}
.total_2 p {
  margin-top: 50px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

/* Knowledge */
.knowledge_main {
  padding-top: 80px;
  padding-bottom: 90px;
}
.knowledge_1 {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 36px;
}
.k1-update {
  flex-wrap: wrap !important;
  justify-content: center;
}
@media (max-width: 1024px) {
  .knowledge_1 {
    flex-wrap: wrap;
  }
}
.knowledge_2 {
  max-width: 370px;
  width: 100%;
  padding-top: 34px;
  padding-bottom: 18px;
  border-top: 2px solid #e9f0fc;
  border-right: 2px solid #e9f0fc;
  border-left: 2px solid #e9f0fc;
  border-bottom: 2px solid #e9f0fc;
  background: #fff;
}
.k2-update {
  padding-top: 0px;
}
.knowledge_2 h1 {
  color: #222;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;
  padding-left: 36px;
  padding-right: 36px;
}
.knowledge_2 p {
  color: #72839a;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 33px;
}
.k34 {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.k34-update {
  justify-content: end !important;
}
.knowledge_3 {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 16px;
  padding-left: 36px;
}
.knowledge_3 h2 {
  color: #72839a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.25px;
  text-transform: capitalize;
}
.knowledge_4 {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-right: 36px;
}
.knowledge_4 h2 {
  color: #72839a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.25px;
  text-transform: capitalize;
}
.kimg {
  width: 100%;
  height: 350px;
  object-fit: cover;
}
.opacity {
  position: absolute;
  background-color: #000;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.readall {
  margin-top: 16px !important;
  max-width: 1225px;
  width: 100%;
  margin: auto;
  text-align: right !important;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: end;
}
/* News */
.news_1 {
  color: #fff;
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 55px;
  margin-bottom: 37px;
}
.news_2 {
  max-width: 216px;
  width: 100%;
  height: 130px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

/* Contact */
.contact_main {
  position: relative;
}
.contactbg {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -10;
  object-fit: cover;
  height: 940px;
}
@media (max-width: 1024px) {
  .contactbg {
    height: 100%;
  }
}
.contact_main1 {
  padding-top: 100px;
  /* padding-bottom: 100px; */
}
.contact_1 {
  max-width: 1060px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 28px;
  padding-right: 28px;
  position: relative;
}
.form1 {
  position: relative;
  margin-top: 70px;
  max-width: 900px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
@media (max-width: 768px) {
  .form1 {
    justify-content: center;
  }
}
.form2 {
  max-width: 375px;
  width: 100%;
}
.form3 {
  width: 100%;
}

.form2,
.form3 p {
  color: #4d5053;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 33px */
  letter-spacing: 0.22px;
  margin-bottom: 8px;
}
.formname {
  border: none;
  background-color: white;
  outline: none;
  width: 100%;
  height: 55px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.formmessage {
  border: none;
  background-color: white;
  outline: none;
  width: 100%;
  height: 130px;
  border-radius: 12px;
  display: flex;
  /* align-items: center; */
  padding-left: 20px;
  resize: none;
  padding-top: 20px;
}
select {
  appearance: none;
}
.subject {
  position: relative;
}
.subject-arrow {
  position: absolute;
  right: 20px;
  top: 55px;
}
.security {
  display: flex;
  align-items: center;
}
.security p {
  width: 100px;
}
.security .formname {
  width: 100px;
}
.btnsend {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  border-radius: 20px;
  background: #ed1b50;
  min-width: 210px;
  height: 50px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ed1b50;
  outline: none;
  cursor: pointer;
}
.btnsend:hover {
  background-color: transparent;
  color: #ed1b50;
}
.cdetail {
  position: absolute;
  left: -150px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 350px;
  width: 100%;
  padding-top: 76px;
  padding-bottom: 76px;
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 50px;
  background: #2b2b2b;
  height: 430px;
  z-index: 10;
}
@media (max-width: 1200px) {
  .form1 {
    margin: auto;
    padding-top: 20px;
  }
  .cdetail {
    position: relative;
    left: 0px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    max-width: 350px;
    width: 100%;
    margin: auto;
    padding-top: 76px;
    padding-bottom: 76px;
    padding-left: 32px;
    padding-right: 32px;
    border-radius: 50px;
    background: #2b2b2b;
    height: 430px;
    z-index: 10;
  }
}
.cdetail_1 {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 30px;
}
.cdetail_1 p {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.2px;
}
.social {
  padding-top: 30px;
  display: flex;
  gap: 50px;
  align-items: center;
}
/* Footer */
.footer_main {
  background-color: #000;
  padding-top: 32px;
  /* padding-bottom: 110px; */
}
.footer_1 {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 20px;
}
@media (max-width: 1024px) {
  .footer_1 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.footer_2 {
  max-width: 350px;
  width: 100%;
}
.footer_2 p {
  margin-top: 20px;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.25px;
}
.footer_3 {
  max-width: 180px;
  width: 100%;
}
.footer_3 h1 {
  color: #fff;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 31.25px;
  margin-bottom: 20px;
}
.footer_3 p {
  margin-bottom: 18px;
  cursor: pointer;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.25px; /* 175% */
}
a {
  text-decoration: none;
  color: #fff;
}
.f_extend {
  max-width: 250px;
  width: 100%;
}
.allrights {
  background-color: #000;
  padding-top: 30px;
  padding-bottom: 10px;
}
.allrights_1 {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 20px;
}
@media (max-width: 768px) {
  .allrights_1 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.allrights_1 p {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.25px;
}
.allrights_2 {
  display: flex;
  gap: 15px;
}
.allrights_2 p {
  width: 140px;
}
@media (max-width: 768px) {
  .hero_1 {
    font-size: 30px;
    text-align: left !important;
  }
  .hero_2 {
    font-size: 30px;
  }
  .hero_3 {
    font-size: 30px;
  }
  .hero_4 {
    font-size: 16px;
  }
  .world_1 {
    font-size: 30px;
    line-height: normal;
  }
  .world_2 {
    font-size: 32px;
  }
  .ch {
    font-size: 25px;
    line-height: normal;
  }
  .world_p {
    font-size: 16px;
  }
  .world_4_1 {
    font-size: 20px;
  }
  .world_6_1 {
    font: 20px;
  }
  .choose_1 {
    font-size: 30px;
    line-height: normal;
  }
  .projects_4 {
    font-size: 30px;
    line-height: normal;
  }
  .profit_1 h1 {
    font-size: 30px;
    line-height: normal;
  }
  .profitspan {
    font-size: 30px;
  }
  .clients_5 {
    font-size: 30px;
  }
  .clients_1 {
    font-size: 30px;
    line-height: normal;
  }
  .history_4 {
    font-size: 30px;
  }
  .total_2 h1 {
    font-size: 30px;
  }
  .news_1 {
    font-size: 30px;
    line-height: normal;
  }
  .clients_6 {
    font-size: 18px;
  }
  .cdetail {
    padding-left: 10px;
    padding-right: 10px;
  }
  .allrights {
    padding-top: 50px;
  }
}
.ps {
  display: none;
}
@media (max-width: 1024px) {
  .ps {
    display: block;
    margin: auto;
  }
  .mt135 {
    margin-top: 0px;
  }
  .mt279_right {
    margin-top: 0px;
    top: 0px;
  }
  .mt250 {
    margin-top: 0px;
  }
  .mt279 {
    margin-top: 0px;
    position: relative;
    top: 0px;
  }
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "" !important;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "" !important;
}

.t1img {
  width: 240px;
}
.testswip > .swiper-wrapper > .swiper-slide {
  border: 2px solid #e9f0fc;
  border-radius: 16px;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25) !important;
}
@media (min-width: 768px) {
  .testswip > .swiper-wrapper > .swiper-slide-next {
    transform: scale(1) !important;
    background: #000 !important;
    border-radius: 16px !important;
    color: white !important;
  }
}
.testswip > .swiper-wrapper > .swiper-slide-next > .testi_3 {
  color: white !important;
}

@media (max-width: 768px) {
  .team_main {
    padding-left: 10px;
    padding-right: 10px;
  }
  .team_1 {
    row-gap: 5px;
  }
  .team_2 {
    width: 150px;
    height: 260px;
  }
  .team_2 img {
    width: 150px;
  }
  .team_2 h1 {
    font-size: 14.438px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.1px;
  }
  .team_2 p {
    font-size: 12px;
    line-height: 16px;
  }
  .footer_2 p {
    text-align: center;
  }
  .footer_3 {
    text-align: center;
  }
  .footer_3 h1 {
    text-align: center;
  }
  .footer_3 p {
    text-align: center;
  }
  .allrights_1 p {
    text-align: center;
  }
  .footer_2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .footer_2 img {
    margin: auto;
    width: 273px;
  }
}
h1 {
  text-align: justify;
}
p {
  text-align: justify;
}
span {
  text-align: justify;
}

.textJustify {
  text-align: justify;
}

.fullwwidth {
  width: 100%;
}

.blog_title {
  font-size: 38px;
  color: var(--Secondary, #000);
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  text-transform: uppercase;
  max-width: 920px;
  width: 100%;
  margin: auto;
}

.blog_content {
  /* max-width: 370px; */
  width: 100%;
  padding-top: 34px;
  padding-bottom: 18px;
  border-top: 2px solid #e9f0fc;
  border-right: 2px solid #e9f0fc;
  border-left: 2px solid #e9f0fc;
  border-bottom: 2px solid #e9f0fc;
  background: #fff;
}
.blog_content h1 {
  color: #222;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;
  padding-left: 36px;
  padding-right: 36px;
}
.blog_content p {
  color: #72839a;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 33px;
}

.blog_image {
  width: 100%;
  height: 350px;
}
.ex_profit_1 p {
  margin-top: 0px !important;
}
/*  */
.port {
  max-width: 370px;
  width: 100%;
  border-top: 2px solid #e9f0fc;
  border-right: 2px solid #e9f0fc;
  border-left: 2px solid #e9f0fc;
  border-bottom: 2px solid #e9f0fc;
  background: #fff;
  position: relative;
}
.port h1 {
  position: absolute;
  bottom: 0px;
  color: #ffff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;
  padding-left: 36px;
  padding-right: 36px;
}

/* Add this style to your CSS/SCSS file */
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: black;
  color: #ed1b50;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  display: none;
  transition: opacity 0.3s;
}

.scroll-to-top-button.visible {
  display: block;
}

/* Case study | Portfolio */

/* CASE Studies */
/* CASE Studies */
.case__a,
.faced,
.faced__a {
  max-width: 1300px;
  width: 100%;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.case__b {
  margin-top: 20px;
  background: white;
  box-shadow: 0px 0px 166px 17px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  gap: 40px;
  align-items: center;
  position: relative;
}
.dots {
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
  z-index: 50;
}
.case__c {
  width: 291px;
  height: 249px;
  position: relative;
}
.guybg {
  width: 291px;
  height: 249px;
  position: absolute;
  background: #d9d9d9;
  bottom: -12px;
  left: -12px;
  z-index: 1;
  border-radius: 5px;
}
.case__c img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: relative;
  z-index: 20;
}
.case__d {
  max-width: 900px;
  width: 100%;
}
.case__d_1 {
  width: 90px;
  height: 6px;
  background: linear-gradient(90deg, #ee1b50 0%, #dd1b4c 100%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.case__d h2 {
  color: #242527;
  font-size: 46px;
  font-weight: 600;
  text-transform: uppercase;
  word-wrap: break-word;
}
.case__d h2 span {
  color: #ee1b50;
  font-size: 46px;
  font-family: Poppins;
  font-weight: 600;
  text-transform: uppercase;
  word-wrap: break-word;
}
.case__d p {
  margin-top: 12px !important;
  text-align: justify;
  color: #737071;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  word-wrap: break-word;
}
.case__d_2 {
  margin-top: 24px;
  display: flex;
  gap: 24px;
  align-items: center;
}
.case__e {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.case__e_1 {
  max-width: 450px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  color: #404040;
  font-size: 26px;
  font-weight: 700;
  line-height: 31.98px;
  box-shadow: 0px 0px 166px 17px rgba(0, 0, 0, 0.05);
  background: white;
  border-radius: 10px;
  padding: 27px 27px 27px 27px;
}
.faced {
  margin-top: 48px;
}
.faced h2 {
  color: #242527;
  font-size: 40px;
  font-weight: 600;
  word-wrap: break-word;
  text-transform: uppercase;
}
.faced span {
  color: #ee1b50;
  font-size: 40px;
  font-weight: 700;
  word-wrap: break-word;
}
.faced p {
  color: #737071;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: justify;
  margin-top: 20px;
}
.faced__a {
  margin-top: 30px;
}
.faced__b {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  padding-bottom: 20px;
}
.faced__b_1 {
  max-width: 880px;
  width: 100%;
}
.faced__b_2 {
  max-width: 250px;
  width: 100%;
}
.faced__b_1 h2 {
  margin-top: 20px;
  color: #737071;
  font-size: 28px;
  font-weight: 600;
}
.faced__b_1 span {
  color: #ed1b50;
}
.faced__b_1 p {
  margin-top: 12px;
  text-align: justify;
  color: #737071;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  word-wrap: break-word;
}
.faced__flex {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.faced__flex h2 {
  margin-top: 10px;
}
.pink {
  color: #ed1b50;
  font-weight: 600;
}
.faced__b_2 h2 {
  color: #737071;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.faced__b_2 .media_logos {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.my10 {
  margin-top: 10px;
  margin-bottom: 10px;
  /* width: 100px; */
}
.public {
  margin-top: 10px;
  width: 254px;
  height: 59px;
  background: white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #404040;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.68px;
  padding-left: 20px;
  padding-right: 20px;
}
.pb20 {
  margin-bottom: 60px;
}
.star__a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.star__b h3 {
  color: #484848;
  font-size: 32px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
}
.star__b h5 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #484848;
  font-size: 16px;
  font-weight: 400;
}
.star__c {
  color: #737071;
  font-size: 16px;
  font-weight: 400;
  width: 182px;
  height: 71px;
  background: white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.pxextra {
  /* padding-left: 50px;
  padding-right: 50px; */
  /* max-width: 1000px; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
}
.pxextra1 {
  max-width: 800px;
}
.pxextra1 p {
  font-weight: 400;
}
@media (max-width: 768px) {
  .case__e,
  .faced__b {
    justify-content: center;
    flex-wrap: wrap;
  }
  .faced_line {
    display: none;
  }
  .case__d h2 {
    color: #242527;
    font-size: 36px;
    font-weight: 600;
  }
  .case__d h2 span {
    color: #ee1b50;
    font-size: 36px;
  }
  .case__b {
    margin-top: 60px;
    background: white;
    box-shadow: 0px 0px 166px 17px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    flex-wrap: wrap;
  }
  .case__c {
    width: 100%;
    height: 100%;
  }
  .faced h2 {
    font-size: 30px;
  }
  .faced span {
    font-size: 30px;
  }
  .faced__b_1 h2 {
    font-size: 20px;
  }
  .faced__b_1 p {
    font-size: 16px;
  }
  .star__b h3 {
    color: #484848;
    font-size: 20px;
  }
}
/* CASE Studies */
/* CASE Studies */

.vidplay {
  color: red;
  font-size: large;
}
@media (min-width: 1200px) {
  .faced__b_ex {
    border-right: 1px solid #dcdcdc;
    padding-right: 100px;
  }
}
.loading {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #dcdcdc;
  border-radius: 50%;
  border-top-color: #ee1b50;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
/* Modal */
.modal1 {
  /* display: none; */
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  z-index: 9000;
}
.modal2 {
  background: white;
  max-width: 800px;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 60px;
}
.modal2 span {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: #fff;
  font-size: 24px;
}
/* Modal */




.faq {
  border-radius: 8px;
  border: 1px solid #ced2da;
  background: #fff;
  box-shadow: 0px 4px 166.7px 0px rgba(0, 0, 0, 0.06);
}
